import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Empty } from 'vant';
import { Button } from 'vant';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Empty), {
      image: "error",
      description: "哎呀，出错啦~"
    }),
    _createVNode(_unref(Button), {
      type: "default",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/pixi')))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("返回设计")
      ])),
      _: 1
    })
  ]))
}
}

})